/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-unsafe-call */
/* eslint-disable @typescript-eslint/no-unsafe-return */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-argument */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable @typescript-eslint/unbound-method */
/* eslint-disable max-lines */
/* eslint-disable max-lines-per-function */
/* eslint-disable max-statements */
/* eslint-disable complexity */
/* eslint-disable @typescript-eslint/ban-ts-comment */
/* eslint-disable import/no-extraneous-dependencies */
import { sharedRef } from '@vue-storefront/core';
import { useUser as useUserComposable } from '@gemini-vsf/composables';
import { useGeminiApi, useApi, useUiNotification, useTranslation, useRecaptcha, useUiState } from '~/composables';
import { computed, useContext } from '@nuxtjs/composition-api';
import { RegisterCustomerBillingAddress } from '~/types/types';
import cookieNames from '~/enums/cookieNameEnum';
import { generateUserData } from './_helpers';

const CREATE_CUSTOMER = `
  mutation createCustomer($input: CustomerCreateInput!) {
    createCustomerV2(input: $input) {
      customer {
        uid
      }
    }
  }
`;

const useUser = () => {
  const {
    app: { $cookies },
  } = useContext();
  const {
    user,
    load: loadUser,
    logout: logoutComposable,
    updateUser: updateUserComposable,
    login: loginComposable,
    changePassword: changePasswordComposable,
    loading,
    error,
  } = useUserComposable();
  const { loginAndRegisterModalOpen, toggleLoginAndRegisterModal } = useUiState();
  const { isEnabled: isRecaptchaEnabled, $recaptcha: recaptcha } = useRecaptcha();
  const { $gt } = useTranslation('translations');
  const { send: sendNotification } = useUiNotification();
  const { query } = useApi();
  const { getCustomerPermissions, addCustomerGroupToCustomer, enrichCustomer, registerCustomerInEmbyon, validateRecaptcha } = useGeminiApi();
  // const userKey = ref(null);
  const customerPermissions = sharedRef(null, 'user-permissions');
  const isAuthenticated = sharedRef(null, 'user-authenticated');
  const registering = sharedRef(false, 'user-registering');
  const setAuthenticatedState = () => {
    isAuthenticated.value = !!$cookies.get(cookieNames.customerCookieName);
  };
  const canBuy = computed(() => customerPermissions.value?.canBuy || false);
  const canLogin = computed(() => customerPermissions.value?.canLogin || false);

  const updatePermissions = async () => {
    setAuthenticatedState();
    customerPermissions.value = await getCustomerPermissions(user.value);
  };

  const load = async () => {
    setAuthenticatedState();
    await loadUser({ customQuery: { customer: 'customerCustom' } });
    await updatePermissions();
  };

  const logout = async () => {
    await logoutComposable();
    setAuthenticatedState();
    await updatePermissions();
  };

  const updateUser = async (params: any) => {
    setAuthenticatedState();
    await updateUserComposable(params);
  };

  const registerNotification = (isError = true) => {
    sendNotification({
      id: isError ? Symbol('create_customer_error') : Symbol('create_customer_success'),
      message: isError
        ? $gt('An error occurred while creating your account. Please retry or contact customer support.')
        : $gt('Account created successfully!'),
      type: isError ? 'danger' : 'success',
      icon: isError ? 'danger' : 'success',
      persist: isError,
      title: isError ? 'Error' : 'Success',
    });
  };

  const register = async (userData: any, groupId: string) => {
    registering.value = true;
    try {
      if (isRecaptchaEnabled.value) {
        const recaptchaToken = await recaptcha.execute('register');
        const recaptchaSuccess = await validateRecaptcha(recaptchaToken);
        if (!recaptchaSuccess) {
          registering.value = false;
          registerNotification();
          return;
        }
      }
      const { email, password, ...baseData } = generateUserData(userData);
      const customerCreateResponse = await query(CREATE_CUSTOMER, {
        input: { email, password, ...baseData },
      });

      if (!customerCreateResponse || customerCreateResponse.errors) {
        registering.value = false;
        registerNotification();
        return;
      }
      // @ts-ignore
      const grn = customerCreateResponse.data.createCustomerV2.customer.uid;
      const enrichCustomerData = {
        grn,
        email: userData.email,
        taxCode: userData.tax_code,
      };
      registering.value = false;
      if (loginAndRegisterModalOpen.value) toggleLoginAndRegisterModal();
      registerNotification(false);
      await Promise.all([addCustomerGroupToCustomer(groupId, grn), enrichCustomer(enrichCustomerData)]);
    } catch (registerJSError) {
      console.error('register ~ error:', registerJSError);
      registerNotification();
    }
    registering.value = false;
  };

  const login = async (params: any) => {
    await loginComposable({ ...params, customQuery: { customer: 'customerCustom' } });
    await updatePermissions();
    setAuthenticatedState();
  };

  const changePassword = async (params: any) => {
    await changePasswordComposable(params);
  };

  const registerInEmbyon = (billingAddress: RegisterCustomerBillingAddress) => {
    try {
      // void promise to avoid waiting for the response
      // eslint-disable-next-line no-void
      void registerCustomerInEmbyon(user.value, billingAddress);
    } catch (e) {
      console.error('registerInEmbyon ~ error:', e);
    }
  };

  return {
    load,
    logout,
    updateUser,
    register,
    login,
    changePassword,
    user,
    isAuthenticated,
    loading,
    error,
    getCustomerPermissions,
    customerPermissions,
    canBuy,
    canLogin,
    setAuthenticatedState,
    registering,
    registerInEmbyon,
  };
};

export default useUser;
